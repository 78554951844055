import loadable from '@loadable/component';

import type { ProductSearchResultFragment } from '#framework/__generated__';

const Argon = loadable(() => import('#components/products/cards/argon'), {
  resolveComponent: (component) => component.Argon,
});
const Neon = loadable(() => import('#components/products/cards/neon'), {
  resolveComponent: (component) => component.Neon,
});
//const Helium = loadable(() => import('#components/products/cards/helium'));
//const Krypton = loadable(
//  () => import('#components/products/cards/krypton') // furniture extra price
//);
//const Xenon = loadable(() => import('#components/products/cards/xenon')); // furniture-two

const MAP_PRODUCT_TO_CARD = {
  argon: Argon,
  neon: Neon,
  //helium: Helium,
  //krypton: Krypton,
  //xenon: Xenon,
};
export interface ProductCardProps {
  result: ProductSearchResultFragment;
  className?: string;
}
export const ProductCard: React.FC<
  ProductCardProps & {
    card: keyof typeof MAP_PRODUCT_TO_CARD;
  }
> = ({
  card = 'argon',
  result,
  className,
  ...props
}: ProductCardProps & {
  card: keyof typeof MAP_PRODUCT_TO_CARD;
}) => {
  const Component = MAP_PRODUCT_TO_CARD[card] ?? Argon;

  return <Component result={result} {...props} className={className} />;
};
