import { useApolloClient, type QueryHookOptions } from '@apollo/client';

import type {
  ProductQuery,
  ProductQueryVariables,
  ProductSearchResultFragment,
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from '#framework/__generated__';
import {
  ProductDocument,
  useSearchProductsQuery as _useSearchProductsQuery,
} from '#framework/__generated__';

export const useSearchProductsQuery = (
  baseOptions: QueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) => {
  const client = useApolloClient();
  return _useSearchProductsQuery({
    ...baseOptions,
    onCompleted(data) {
      baseOptions.onCompleted?.(data);
      if (data.search?.items?.length) {
        // Add the partial products to the cache so they can be used in the product detail query
        data.search.items.forEach((result: ProductSearchResultFragment) => {
          const data = client.readQuery<ProductQuery, ProductQueryVariables>({
            query: ProductDocument,
            variables: { id: result.productId },
          });

          client.cache.writeQuery<
            DeepPartial<ProductQuery>,
            ProductQueryVariables
          >({
            query: ProductDocument,
            variables: { id: result.productId },
            data: {
              product: {
                id: result.productId,
                name: result.productName,
                slug: result.slug,
                description: '',
                assets: result.product.featuredAsset
                  ? [result.product.featuredAsset]
                  : [],
                collections: [],
                facetValues: [],
                optionGroups: [],
                variants: [],
                nutritionInfo: null,
                ...result.product,
                ...data?.product,
              },
            },
            broadcast: true,
          });
        });
        /*data.search.items.forEach((product: ProductInfoFragment) => {
          client.cache.modify<Product>({
            id: client.cache.identify(product),
            fields: {
              assets(existingRefs, { readField }) {
                return (
                  existingRefs ?? [
                    {
                      __ref: readField('featuredAsset', product),
                    },
                  ]
                );
              },
            },
          });
        });*/
      }
    },
  });
};
