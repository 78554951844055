import loadable from '@loadable/component';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import NotFound from '#components/ui/not-found';
import { rangeMap } from '#lib/range-map';

const ProductLoader = loadable(
  () => import('#components/ui/loaders/product-loader'),
  {
    resolveComponent: (component) => component.ProductLoader,
  }
);

interface GridWithLoaderProps {
  children: React.ReactNode;
  hasNextPage: boolean;
  isLoadingMore: boolean;
  layout: 'shop' | 'standard' | 'addons' | 'classic';
  limit?: number;
  notFound: boolean;
  onLoadMore: () => void;
  showLoader: boolean;
}
export const GridWithLoader = ({
  children,
  hasNextPage,
  isLoadingMore,
  layout,
  notFound,
  onLoadMore,
  showLoader,
  limit = 15,
}: GridWithLoaderProps) => {
  const { t } = useTranslation('common');

  const renderLayoutClassName = () => {
    switch (layout) {
      case 'standard':
        return 'md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 bg-gray-100';
      case 'shop':
        return 'lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-6 bg-gray-100';
      case 'addons':
        return 'grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-2';
      case 'classic':
        return 'mt-2 md:mt-3 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 bg-gray-100';
      default:
        return 'md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 bg-gray-100';
    }
  };

  if (notFound) {
    return (
      <div className='bg-gray-100 w-full min-h-full pt-6 pb-8 px-4 lg:p-8'>
        <NotFound text='text.no-products' className='w-7/12 mx-auto' />
      </div>
    );
  }

  return (
    <div
      className={cn('flex-1 lg:pt-0', {
        'px-0 lg:px-0':
          layout === 'shop' ||
          layout === 'addons' ||
          (layout as string) === 'modern',
        'px-4':
          layout !== 'shop' &&
          layout !== 'addons' &&
          (layout as string) !== 'modern',
        'pb-8 lg:p-8': layout !== 'addons',
      })}
    >
      <div
        className={cn(
          'grid grid-cols-1 sm:grid-cols-2 gap-3',
          renderLayoutClassName()
        )}
      >
        {children}
        {showLoader && (
          <>
            {rangeMap(limit, (i) => (
              <ProductLoader key={i} uniqueKey={`product-${i}`} />
            ))}
          </>
        )}
      </div>
      {hasNextPage && (
        <div className='flex justify-center mt-8 lg:mt-12'>
          <Waypoint
            topOffset='-100'
            onEnter={() => !showLoader && onLoadMore()}
          />
        </div>
      )}
    </div>
  );
};
